// Firebase
import { DocumentData } from "firebase/firestore";

// Interfaces
import { Base } from "../interfaces";

// this function is curried in order to pass a type, i.e. T, as a parameter.
// it is used to format the data receive from firestore into whatever we need.
//
// For exemple, firestore sends dates as { seconds, nanoseconds }. We can
// format them to an actual date.

// eslint-disable-next-line
export const genDoc: <T extends Base>() => (doc: DocumentData) => T =
  <T>() =>
  (doc: DocumentData) => {
    if (doc) {
      const data = doc.data();

      if (data) {
        if (data.dateOfBirth)
          data.dateOfBirth = new Date(data.dateOfBirth.seconds * 1000);
        data.createdAt = new Date(data.createdAt.seconds * 1000);
        data.updatedAt = new Date(data.updatedAt.seconds * 1000);
      }

      return data as T;
    }
    return {} as T;
  };
