// React
import React from "react";
// Interfaces
import { Loan } from "../../../interfaces";
//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField"

import { FaCopy } from "react-icons/fa";

interface Props {
  loan: Loan;
}

const Withdrawal: React.FC<Props> = ({ loan }) => {
  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Cryptocurrency</TableCell>
            <TableCell align="center">Wallet Address</TableCell>
            <TableCell align="center">Copy Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{loan.cryptocurrency}</TableCell>
            <TableCell align="center">{loan.walletAddress}</TableCell>
            <TableCell align="center" className="user__button">
              <FaCopy
                size={20}
                onClick={() =>
                  navigator.clipboard.writeText(loan?.walletAddress ?? "")
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <TextField fullWidth variant="standard" value={loan.transactionHash} label="Transaction Hash" inputProps={{ readOnly: true }} />
    </>
  );
};

export default Withdrawal;
