// React
import React, { useEffect, useState } from "react";
// Interfaces
import { User, Loan } from "../../../interfaces";
// Custom Hooks
import useFetchCryptoPrice from "../../../hooks/useFetchCryptoPrice";
// Assets
import btcLogo from "../../../assets/images/logos/btcLogo.svg";
import ethLogo from "../../../assets/images/logos/ethLogo.svg";
import bnbLogo from "../../../assets/images/logos/bnbLogo.svg";
// MUI + UI
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { BsCurrencyDollar } from "react-icons/bs";
import { MdArrowRightAlt } from "react-icons/md";

interface Props {
  loanProp: Loan;
  user: User;
}

const General: React.FC<Props> = ({ loanProp, user }) => {
  // States
  const [loan] = useState<Loan>(loanProp);
  const [loading, setLoading] = useState(false);
  const cryptoPrice = useFetchCryptoPrice({ loan, setLoading });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        height: "auto",
        marginTop: "60px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h6">
          Start Date: {new Date(loan.createdAt as Date).toDateString()}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <Paper className="paper-block" elevation={2}>
          <Typography variant="h6">Loan Amount </Typography>
          <Typography className="typography" variant="h4">
            {loan.loanAmount} <BsCurrencyDollar />
          </Typography>
        </Paper>
        <Paper className="paper-block" elevation={2}>
          <Typography variant="h6">Current Balance</Typography>
          <Typography variant="h4" className="typography">
            {loan.currentBalance} <BsCurrencyDollar />
          </Typography>
        </Paper>
        <Paper className="paper-block" elevation={2}>
          <Typography variant="h6">Cryptocurrency</Typography>
          <Typography variant="h4" className="typography">
            {loan.cryptocurrency}
            {"    "}
            <img
              style={{ height: "30px", width: "30px", margin: "10px" }}
              src={
                loan.cryptocurrency === "BTC"
                  ? btcLogo
                  : loan.cryptocurrency === "ETH"
                  ? ethLogo
                  : bnbLogo
              }
              alt=""
            />
          </Typography>
        </Paper>
        <Paper className="paper-block margin" elevation={2}>
          <Typography variant="h6">Purchased Coin Price</Typography>
          <Typography className="typography" variant="h4">
            {parseInt(loan.binance.coinPrice).toFixed(2)}
            <BsCurrencyDollar />
          </Typography>
        </Paper>
        <Paper className="paper-block margin" elevation={2}>
          <Typography variant="h6">Current loan value</Typography>
          {loading ? (
            <CircularProgress
              size={10}
              style={{ marginLeft: 10, color: "black" }}
            />
          ) : (
            <Typography
              className="typography"
              variant="h4"
              style={{
                margin: 0,
                color: cryptoPrice > loan.binance.coinPrice ? "green" : "red",
              }}
            >
              {(
                parseFloat(loan.binance.coinAmount) * parseFloat(cryptoPrice)
              ).toFixed(2)}{" "}
              <BsCurrencyDollar />
            </Typography>
          )}
        </Paper>
        <Paper className="paper-block margin" elevation={2}>
          <Typography variant="h6">Current Coin Price</Typography>
          {loading ? (
            <CircularProgress
              size={10}
              style={{ marginLeft: 10, color: "black" }}
            />
          ) : (
            <Typography className="typography" variant="h4">
              {parseInt(cryptoPrice).toFixed(2)}
              <BsCurrencyDollar />
            </Typography>
          )}
        </Paper>
      </div>
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "center",
          gap: "125px",
        }}
      >
        <Button
          style={{
            width: "18%",
            backgroundColor: "#076AE1",
            color: "white",
            padding: "20px",
            textTransform: "none",
            fontSize: "medium",
          }}
          variant="contained"
          target="_blank"
          href={`https://cryptocredit.chargify.com/subscriptions/${loan.zum.subscriptionId}`}
        >
          Chargify Subscription <MdArrowRightAlt />
        </Button>
        <Button
          style={{
            width: "18%",
            backgroundColor: "#076AE1",
            color: "white",
            padding: "20px",
            textTransform: "none",
            fontSize: "medium",
          }}
          variant="contained"
          target="_blank"
          href={` https://cockpit.sumsub.com/checkus#/applicant/${user?.sumsubStatus.sumsubId}`}
        >
          Sumsub User <MdArrowRightAlt />
        </Button>
      </div>
    </div>
  );
};

export default General;
