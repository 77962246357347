import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Loan } from "../interfaces";

interface Props {
  loan: Loan;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const useFetchCryptoPrice = ({ loan, setLoading }: Props) => {
  const [cryptoPrice, setCryptoPrice] = useState("");
  let currentLoanValue = 0;

  useEffect(() => {
    setLoading(true);
    let url = "";
    switch (loan.cryptocurrency) {
      case "BTC":
        url = "wss://stream.binance.com:9443/ws/btcusdt@ticker";
        break;
      case "ETH":
        url = "wss://stream.binance.com:9443/ws/ethusdt@ticker";
        break;
      case "BNB":
        url = "wss://stream.binance.com:9443/ws/bnbusdt@ticker";
        break;
    }
    // WEBHOOKS
    //prettier-ignore
    const cryptoWebsocket = new WebSocket(url);

    cryptoWebsocket.onmessage = (e: any) => {
      const object = JSON.parse(e.data);
      const currentPrice = parseFloat(object.o);
      setCryptoPrice(currentPrice.toFixed(2));
      currentLoanValue = parseFloat(loan.binance.coinAmount) * currentPrice;
      setLoading(false);
    };

    return () => {
      cryptoWebsocket.close();
    };
  }, []);

  return cryptoPrice;
};

export default useFetchCryptoPrice;
