// React
import React, { useEffect, useState } from "react";
// React-Router-Dom
import { useNavigate } from "react-router-dom";
// Interfaces
import { Loan } from "../../interfaces";
// Custom Hooks
import { useFetchUser } from "../../hooks/useFetchUser";
// Settings
import settings from "../../settings.json";
// Custom components
import LoanTable from "../general/SimpleTable";
import LoanTableRows from "./utils/LoanTableRows";
import SearchBar from "../general/SearchBar";
// Firebase
import { firestore } from "../../firebase/firebase";
import {
  collection,
  query,
  orderBy,
  doc,
  onSnapshot,
  startAfter,
  limit,
  endBefore,
  QueryDocumentSnapshot,
  DocumentData,
  getDocs,
  where,
} from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

const COLLECTION = "Loans";

const Loans: React.FC = () => {
  //Hooks
  const navigate = useNavigate();
  const { currentUser, loading, setLoading } = useFetchUser();

  // States
  const [loans, setLoans] = useState<Loan[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [firstVisible, setFirstVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchFirstLoans = () => {
    try {
      setLoading(true);
      const localQuery = query(
        collection(firestore, COLLECTION),
        orderBy("createdAt", "asc"),
        limit(settings.page.rowsPerPage)
      );

      return onSnapshot(localQuery, (doc) => {
        const data = doc.docs.map(genDoc<Loan>());
        setFirstVisible(doc.docs[0]);
        setLastVisible(doc.docs[doc.docs.length - 1]);
        setLoans(data);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = async () => {
    try {
      if (loans.length !== 0) {
        setLoading(true);
        const localQuery = query(
          collection(firestore, COLLECTION),
          orderBy("email", "asc"),
          limit(settings.page.rowsPerPage),
          startAfter(lastVisible)
        );

        return onSnapshot(localQuery, (doc) => {
          const data = doc.docs.map(genDoc<Loan>()).filter((item: Loan) => {
            if (
              currentUser &&
              currentUser.roles.includes(settings.app.highestRole)
            ) {
              return true;
            }
            return item;
          });

          setFirstVisible(doc.docs[0]);
          setLastVisible(doc.docs[doc.docs.length - 1]);
          setLoans(data);
          setLoading(false);
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = async () => {
    try {
      setLoading(true);
      const localQuery = query(
        collection(firestore, COLLECTION),
        orderBy("email", "asc"),
        limit(settings.page.rowsPerPage),
        endBefore(firstVisible)
      );

      return onSnapshot(localQuery, (doc) => {
        const data = doc.docs.map(genDoc<Loan>()).filter((item: Loan) => {
          if (
            currentUser &&
            currentUser.roles.includes(settings.app.highestRole)
          ) {
            return true;
          }
          return item;
        });

        setFirstVisible(doc.docs[0]);
        setLastVisible(doc.docs[doc.docs.length - 1]);
        setLoans(data);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCount = () => {
    try {
      setLoading(true);
      return onSnapshot(doc(firestore, "Metadata/Loans"), (document) => {
        if (!document.exists) setCount(0);

        const data = document.data() as any;

        setCount(data.count);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e: any, newPage: number) => {
    if (page < newPage) handleNextPage();
    else if (page > newPage) handlePreviousPage();

    setPage(newPage);
  };

  const fetchFilteredUsers = async (id: string) => {
    const localQuery = query(
      collection(firestore, COLLECTION),
      orderBy("createdAt", "asc"),
      where("id", "==", id)
      // limit(settings.page.rowsPerPage)
      // where("email", ">=", searchQuery),
      // where("email", "<=", `${searchQuery + "\uf8ff"}`)
    );

    const data = await getDocs(localQuery);

    const localFilteredUsers = data.docs
      .map(genDoc<Loan>())
      .filter((u) => u.id.toLowerCase().includes(searchQuery.toLowerCase()));

    if (localFilteredUsers.length > 0) {
      const firstIndex = data.docs.findIndex((d) => {
        const localData = d.data();
        return localData.id === localFilteredUsers[0].id;
      });

      const lastIndex = data.docs.findIndex((d) => {
        const localData = d.data();

        if (localFilteredUsers.length >= 10) {
          return (
            localData.id ===
            localFilteredUsers[settings.page.rowsPerPage - 1].id
          );
        } else {
          return (
            localData.id ===
            localFilteredUsers[localFilteredUsers.length - 1].id
          );
        }
      });

      setFirstVisible(data.docs[firstIndex]);
      setLastVisible(data.docs[lastIndex]);
    }

    setCount(settings.page.rowsPerPage);
    // setCount(localFilteredUsers.length);
    setLoans(localFilteredUsers);
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    setPage(0);
  };

  useEffect(() => {
    let unsub1: any = null;
    let unsub2: any = null;

    if (searchQuery) fetchFilteredUsers(searchQuery);
    else {
      setLoading(true);
      unsub1 = fetchCount();
      unsub2 = fetchFirstLoans();
      setLoading(false);
    }

    return () => {
      typeof unsub1 === "function" && unsub1();
      typeof unsub2 === "function" && unsub2();
    };
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    const unsub1 = fetchCount();
    const unsub2 = fetchFirstLoans();
    setLoading(false);

    return () => {
      typeof unsub1 === "function" && unsub1();
      typeof unsub2 === "function" && unsub2();
    };
  }, []);

  return (
    <>
      <SearchBar
        title="Loans"
        searchTitle="Search by email"
        onSearch={handleSearch}
      />
      <LoanTable
        loading={loading}
        data={loans}
        count={count}
        rows={LoanTableRows()}
        noMoreData={loans.length <= count}
        rowsPerPage={settings.page.rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onDoubleClick={(u: Loan) => navigate(`/loan/${u.id}`, { state: u })}
      />
    </>
  );
};

export default Loans;
