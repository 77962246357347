// React
import { NavLink } from "react-router-dom";
// Firebase
import { auth } from "../../../firebase/firebase";
// Utils
import settings from "../../../settings.json";
// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";
import { useFetchWithdrawableLoans } from "../../../hooks/useFetchWithdrawableLoans";
// Mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import Badge from "@mui/material/Badge";

// Icons
import {
  MdHome,
  MdPeople,
  MdPowerSettingsNew,
  MdQueryBuilder,
  MdOutlineTextSnippet,
} from "react-icons/md";

type DrawerItemProps = {
  to: string;
  onClick?: any;
  icon?: React.FC;
  primaryText: string;
  color?: string;
  mailBadge?: number;
};

const DrawerItem: React.FC<DrawerItemProps> = (props: DrawerItemProps) => {
  return (
    <NavLink
      to={props.to}
      onClick={() => props.onClick ?? null}
      className="drawer__nav__item"
    >
      <ListItem
        button
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <ListItemIcon>{props.icon ? <props.icon /> : null}</ListItemIcon>
        <ListItemText
          primary={props.primaryText}
          color={props.color ?? "primary"}
        ></ListItemText>
        {props.mailBadge !== undefined && (
          <ListItemIcon>
            &nbsp; <Badge badgeContent={props.mailBadge} color="primary" />
          </ListItemIcon>
        )}
      </ListItem>
    </NavLink>
  );
};

const DrawerContent = () => {
  const { currentUser } = useFetchUser();
  const { withdrawableLoans } = useFetchWithdrawableLoans();

  return (
    <div className="drawer__nav">
      <List>
        <ListSubheader inset>Info</ListSubheader>
        <DrawerItem to="/home" icon={MdHome} primaryText="Home" />
        <DrawerItem to="/admins" icon={MdPeople} primaryText="Admins" />
        <DrawerItem to="/users" icon={MdPeople} primaryText="Users" />
        <DrawerItem
          to="/loans"
          icon={MdOutlineTextSnippet}
          primaryText="Loans"
        />
        <DrawerItem
          to="/withdrawable-loans"
          icon={MdOutlineTextSnippet}
          primaryText="Paid Loans"
          mailBadge={withdrawableLoans.length}
        />
      </List>
      <Divider />
      <List>
        <ListSubheader inset>Settings</ListSubheader>
        {currentUser &&
          currentUser.roles &&
          currentUser.roles.length > 0 &&
          currentUser.roles.includes(settings.app.highestRole) && (
            <DrawerItem
              to="/journals"
              icon={MdQueryBuilder}
              primaryText="Journals"
            />
          )}
        <div
          className="drawer__nav__item"
          onClick={() => {
            auth.signOut();
          }}
        >
          <ListItem button>
            <ListItemIcon>
              <MdPowerSettingsNew />
            </ListItemIcon>
            <ListItemText primary="Logout" color="primary" />
          </ListItem>
        </div>
      </List>
    </div>
  );
};

export default DrawerContent;
