// React
import React, { useEffect, useState } from "react";
// React-Router-Dom
import { useNavigate } from "react-router-dom";
// Interfaces
import { Loan } from "../../interfaces";
// Custom Hooks
import { useFetchUser } from "../../hooks/useFetchUser";
import { useFetchWithdrawableLoans } from "../../hooks/useFetchWithdrawableLoans";
// Custom components
import LoanTable from "../general/SimpleTable";
import LoanTableRows from "./utils/LoanTableRows";
import SearchBar from "../general/SearchBar";
// Firebase
import { firestore } from "../../firebase/firebase";
import { collection, query, orderBy, getDocs, where } from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

const COLLECTION = "Loans";

const Loans: React.FC = () => {
  // Hooks
  const navigate = useNavigate();
  const { loading, setLoading } = useFetchUser();
  const { withdrawableLoans } = useFetchWithdrawableLoans();
  // States
  const [loans, setLoans] = useState<Loan[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    let unsub1: any = null;
    if (searchQuery) fetchFilteredUsers(searchQuery);
    else {
      setLoans(withdrawableLoans);
    }

    return () => {
      typeof unsub1 === "function" && unsub1();
    };
  }, [searchQuery]);

  const fetchFilteredUsers = async (id: string) => {
    const localQuery = query(
      collection(firestore, COLLECTION),
      orderBy("updatedAt", "desc"),
      where("id", "==", id),
      where("status", "==", "paid")
    );

    const data = await getDocs(localQuery);

    const localFilteredUsers = data.docs
      .map(genDoc<Loan>())
      .filter((u) => u.id.toLowerCase().includes(searchQuery.toLowerCase()));

    setLoans(localFilteredUsers);
  };

  return (
    <>
      <SearchBar
        title="Loans"
        searchTitle="Search by email"
        onSearch={handleSearch}
      />
      <LoanTable
        loading={loading}
        data={loans}
        rows={LoanTableRows()}
        onDoubleClick={(u: Loan) => navigate(`/loan/${u.id}`, { state: u })}
      />
    </>
  );
};

export default Loans;
