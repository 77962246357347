import React, { ReactNode } from "react";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Custom components
import Loading from "../../general/Loading";

interface Props {
  children: ReactNode;
}

const BaseLayout: React.FC<Props> = ({ children }) => {
  const { loading } = useFetchUser();

  return (
    <>
      <Loading loading={loading} />
      <div className="baseLayout__container">
        <main>
          <div className="baseLayout__toolbar" />
          {children}
        </main>
      </div>
    </>
  );
};

export default BaseLayout;
