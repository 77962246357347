import React from "react";

//MUI
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  data: any[];
  noMoreData: boolean;
  loading: boolean;
  colSpan?: number;
}

const TableLoader: React.FC<Props> = ({
  data,
  noMoreData,
  loading,
  colSpan = 7,
}) => {
  if (loading)
    return (
      <TableRow>
        <TableCell colSpan={colSpan} align="center">
          <CircularProgress />
        </TableCell>
      </TableRow>
    );

  // if (!data.length && noMoreData)
  //     return (
  //         <TableRow>
  //             <TableCell colSpan={colSpan} align="center">
  //                 Fin des données
  //             </TableCell>
  //         </TableRow>
  //     );

  if (!data.length && noMoreData /*&& !loading*/)
    return (
      <TableRow>
        <TableCell colSpan={colSpan} align="center">
          Aucune donnée
        </TableCell>
      </TableRow>
    );

  if (!data.length && !noMoreData && !loading)
    return (
      <TableRow>
        <TableCell colSpan={colSpan} align="center">
          Requête introuvable
        </TableCell>
      </TableRow>
    );

  return <></>;
};

export default TableLoader;
