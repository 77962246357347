// React
import React, { useEffect, useState } from "react";
// Hooks
import { useSnackbar } from "notistack";
// Interfaces
import { User, Loan } from "../../interfaces";
// Custom components
import Title from "../general/Title";
// Custom Hooks
import { useFetchUser } from "../../hooks/useFetchUser";
// React-Router-Dom
import { useParams, useNavigate } from "react-router-dom";
// Utils
import { emailRegex } from "../../utils/regex";
// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Mui core
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
// Firebase
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getDoc,
  query,
  serverTimestamp,
  getDocs,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase/firebase";

// yup validation
const requiredMessage = "This field is required.";

const userSchema = yup.object({
  firstName: yup.string().required(requiredMessage),
  lastName: yup.string().required(requiredMessage),
  email: yup
    .string()
    .matches(emailRegex, "Invalid Email")
    .required(requiredMessage),
  lastLogin: yup.date(),
  phone: yup.string(),
  dateOfBirth: yup.date(),
  street: yup.string(),
  city: yup.string(),
  addressNumber: yup.string(),
  postalCode: yup.string(),
  country: yup.string(),
});

const UserInfo: React.FC = () => {
  const { currentUser } = useFetchUser();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //States
  const [user, setUser] = useState<User>();
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [userReviewStatus, setUserReviewStatus] = useState("");

  const defaultValues = {
    firstName: user ? user.firstName : "",
    lastName: user ? user.lastName : "",
    email: user ? user.email : "",
    lastLogin: user ? user.lastLogin : "",
    phone: user ? user.phone : "",
    dateOfBirth: user ? user.dateOfBirth : "",
    addressNumber: user ? user.address.street_number : "",
    street: user ? user.address.route : "",
    city: user ? user.address.locality : "",
    postalCode: user ? user.address.postal_code : "",
    country: user ? user.address.country : "",
    reviewStatus: user ? user.sumsubStatus.reviewStatus : "",
  };

  // Forms
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({ resolver: yupResolver(userSchema), defaultValues });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const userData = {
        address: {
          country: data.country,
          locality: data.city,
          postal_code: data.postalCode,
          street_number: data.addressNumber,
          route: data.street,
        },
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
      };

      if (user && user.id) {
        await updateDoc(doc(firestore, "Users", user.id), { ...userData });

        await addDoc(collection(firestore, "Logs"), {
          id: user.id,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          adminId: currentUser && currentUser.id ? currentUser.id : "",
          reason: `Updating user ${
            data && (data as any).email ? (data as any).email : (data as any).id
          }`,
        });

        enqueueSnackbar("User updated!", { variant: "success" });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
      //setValue("email", user.email);
      setValue("phone", user.phone);
      //setValue("lastLogin", user.lastLogin.toDate().toDateString());
      setValue("dateOfBirth", user.dateOfBirth.toDate().toDateString());
      setValue("addressNumber", user.address.street_number);
      setValue("street", user.address.route);
      setValue("city", user.address.locality);
      setValue("postalCode", user.address.postal_code);
      setValue("country", user.address.country);
      if (user.sumsubStatus.reviewStatus === "") {
        setValue("reviewStatus", "Not reviewed yet");
      } else {
        setValue("reviewStatus", user.sumsubStatus.reviewStatus);
      }
    }
  }, [user]);

  useEffect(() => {
    const fetchUser = async () => {
      const result = doc(firestore, "Users", id as string);

      const querySnapshot = await getDoc(result);
      setUser(querySnapshot.data() as User);
    };
    if (loans.length <= 0) {
      const fetchLoans = async () => {
        const loansRef = collection(firestore, "Loans");
        const results = query(loansRef, where("userId", "==", id));

        const querySnapshot = await getDocs(results);
        querySnapshot.forEach((doc: any) => {
          // doc.data() is never undefined for query doc snapshots
          setLoans((prevState) => [...prevState, doc.data()] as Loan[]);
        });
      };
      fetchLoans();
    }
    fetchUser();
  }, []);
  return (
    <div className="user__container">
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          <Typography>
            Are you sure you want to change the status from{" "}
            <span style={{ fontWeight: "bold" }}>
              {user?.sumsubStatus.reviewStatus}
            </span>{" "}
            to{" "}
            <span style={{ fontWeight: "bold" }}>
              {userReviewStatus.charAt(0).toUpperCase() +
                userReviewStatus.slice(1)}
              ?
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => setOpen(false)}
              style={{ marginRight: "20px" }}
              variant="outlined"
              type="submit"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (user)
                  setUser({
                    ...user,
                    sumsubStatus: {
                      ...user?.sumsubStatus,
                      reviewStatus: userReviewStatus,
                    },
                  });
                setOpen(false);
              }}
              color="primary"
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <div className="header">
        <Title>
          <Link
            href={
              "https://cockpit.sumsub.com/checkus#/applicant/" +
              user?.sumsubStatus.sumsubId
            }
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#076AE1" }}
          >
            {user?.firstName} {user?.lastName}
          </Link>
        </Title>
        {user && (
          <>
            <div>
              <Select
                variant="standard"
                value={user.sumsubStatus.reviewStatus}
                label="Status"
                onChange={(e) => {
                  if (user) {
                    setUserReviewStatus(e.target.value);
                    setOpen(true);
                  }
                }}
              >
                <MenuItem value={"completed"}>Completed</MenuItem>
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"prechecked"}>Prechecked</MenuItem>
                <MenuItem value={"queued"}>Queued</MenuItem>
                <MenuItem value={"init"}>Init</MenuItem>
                <MenuItem value={"onHold"}>On Hold</MenuItem>
              </Select>
            </div>
          </>
        )}
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={12}>
          <Grid item sm={12} md={6}>
            <h2 style={{ color: "#8a8a8a" }}>Profile</h2>
            <Grid container spacing={2}>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={
                        errors.firstName?.message as unknown as String
                      }
                      error={!!errors.firstName?.message}
                      label="First Name"
                      variant="standard"
                      required
                      fullWidth
                      {...field}
                      inputProps={
                        user?.sumsubStatus.reviewStatus === "completed"
                          ? { readOnly: true }
                          : { readOnly: false }
                      }
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="firstName"
                  control={control}
                />
              </Grid>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={errors.lastName?.message as unknown as String}
                      error={!!errors.lastName?.message}
                      label="Last Name"
                      variant="standard"
                      required
                      fullWidth
                      {...field}
                      inputProps={
                        user?.sumsubStatus.reviewStatus === "completed"
                          ? { readOnly: true }
                          : { readOnly: false }
                      }
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="lastName"
                  control={control}
                />
              </Grid>

              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={errors.email ? errors.email.message : ""}
                      error={!!errors.email?.message}
                      label="Email"
                      variant="standard"
                      fullWidth
                      required
                      {...field}
                      inputProps={{ readOnly: true }}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="email"
                  control={control}
                />
              </Grid>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={errors.phone?.message as unknown as String}
                      error={!!errors.phone?.message}
                      label="Phone Number"
                      variant="standard"
                      required
                      fullWidth
                      {...field}
                      inputProps={
                        user?.sumsubStatus.reviewStatus === ""
                          ? { readOnly: true }
                          : { readOnly: false }
                      }
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="phone"
                  control={control}
                />
              </Grid>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={
                        errors.lastLogin?.message as unknown as String
                      }
                      error={!!errors.lastLogin?.message}
                      label="last Login"
                      variant="standard"
                      required
                      fullWidth
                      {...field}
                      inputProps={{ readOnly: true }}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="lastLogin"
                  control={control}
                />
              </Grid>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={
                        errors.dateOfBirth?.message as unknown as String
                      }
                      error={!!errors.dateOfBirth?.message}
                      label="Date of Birth"
                      variant="standard"
                      required
                      fullWidth
                      {...field}
                      inputProps={
                        user?.sumsubStatus.reviewStatus === "completed"
                          ? { readOnly: true }
                          : { readOnly: false }
                      }
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="dateOfBirth"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <h2 style={{ color: "#8a8a8a" }}>Address</h2>
            <Grid spacing={2} container>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={
                        errors.addressNumber?.message as unknown as String
                      }
                      error={!!errors.addressNumber?.message}
                      label="Address Number"
                      variant="standard"
                      fullWidth
                      required
                      {...field}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="addressNumber"
                  control={control}
                />
              </Grid>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={errors.street?.message as unknown as String}
                      error={!!errors.street?.message}
                      label="Street"
                      fullWidth
                      variant="standard"
                      required
                      {...field}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="street"
                  control={control}
                />
              </Grid>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={
                        errors.postalCode?.message as unknown as String
                      }
                      error={!!errors.postalCode?.message}
                      label="postal Code"
                      fullWidth
                      variant="standard"
                      required
                      {...field}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="postalCode"
                  control={control}
                />
              </Grid>
              <Grid item sm={4} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={errors.city?.message as unknown as String}
                      error={!!errors.city?.message}
                      label="City"
                      variant="standard"
                      required
                      fullWidth
                      {...field}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="city"
                  control={control}
                />
              </Grid>

              <Grid item sm={4} md={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      helperText={errors.country?.message as unknown as String}
                      error={!!errors.country?.message}
                      label="Country"
                      variant="standard"
                      fullWidth
                      required
                      {...field}
                      style={{ marginBottom: 10 }}
                    />
                  )}
                  name="country"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer
          style={{ display: "flex", justifyContent: "center" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Loans</TableCell>
                <TableCell align="center">Coin</TableCell>
                <TableCell align="center">Amount owned</TableCell>
                <TableCell align="center">Loan amount</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loans.map((loan, i) => (
                <TableRow key={i}>
                  <TableCell
                    onClick={() =>
                      navigate(`/loans/${loan.id}`, { state: loan })
                    }
                    align="left"
                    className="hover"
                  >
                    {loan.id}
                  </TableCell>
                  <TableCell align="center">{loan.cryptocurrency}</TableCell>
                  <TableCell align="center">
                    {loan.binance.coinAmount}
                  </TableCell>
                  <TableCell align="center">{loan.loanAmount}$</TableCell>
                  <TableCell align="center">{loan.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{ marginLeft: "auto", marginTop: 20 }}
            disabled={loading}
          >
            {loading ? "Updating" : "Update"}
            {loading ? (
              <CircularProgress
                size={10}
                style={{ marginLeft: 10, color: "black" }}
              />
            ) : (
              ""
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserInfo;
