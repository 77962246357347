import React, { useEffect, useState } from "react";

// Components
import LogTable from "../general/SimpleTable";
import LogsTableRows from "./LogsTableRows";
import settings from "../../settings.json";
import SearchBar from "../general/SearchBar";

// Firebase
import { firestore } from "../../firebase/firebase";
import {
  collection,
  query,
  orderBy,
  doc,
  onSnapshot,
  startAfter,
  limit,
  endBefore,
  QueryDocumentSnapshot,
  DocumentData,
  getDocs,
} from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Interfaces
import { Log } from "../../interfaces";
import { AdminUser } from "../../interfaces";

const COLLECTION = "Logs";

const Logs: React.FC = () => {
  const { currentUser, loading, setLoading } = useFetchUser();

  // States
  const [logs, setLogs] = useState<Log[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [firstVisible, setFirstVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [users, setUsers] = useState<AdminUser[]>([]);

  const handleNextPage = async () => {
    try {
      if (logs.length !== 0) {
        setLoading(true);
        const localQuery = query(
          collection(firestore, COLLECTION),
          orderBy("createdAt", "desc"),
          limit(settings.page.rowsPerPage),
          startAfter(lastVisible)
        );

        return onSnapshot(localQuery, (doc) => {
          const data = doc.docs.map(genDoc<Log>()).filter((item: Log) => {
            if (
              currentUser &&
              currentUser.roles.includes(settings.app.highestRole)
            ) {
              return true;
            }
            return item;
          });

          setFirstVisible(doc.docs[0]);
          setLastVisible(doc.docs[doc.docs.length - 1]);
          setLogs(data);
          setLoading(false);
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = async () => {
    try {
      setLoading(true);
      const localQuery = query(
        collection(firestore, COLLECTION),
        orderBy("createdAt", "desc"),
        limit(settings.page.rowsPerPage),
        endBefore(firstVisible)
      );

      return onSnapshot(localQuery, (doc) => {
        const data = doc.docs.map(genDoc<Log>()).filter((item: Log) => {
          if (
            currentUser &&
            currentUser.roles.includes(settings.app.highestRole)
          ) {
            return true;
          }
          return item;
        });

        setFirstVisible(doc.docs[0]);
        setLastVisible(doc.docs[doc.docs.length - 1]);
        setLogs(data);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCount = () => {
    try {
      setLoading(true);
      return onSnapshot(doc(firestore, "Metadata/Logs"), (document) => {
        if (!document.exists) setCount(0);

        const data = document.data() as any;

        setCount(data.count);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e: any, newPage: number) => {
    if (page < newPage) handleNextPage();
    else if (page > newPage) handlePreviousPage();

    setPage(newPage);
  };

  const fetchFirstLogs = () => {
    try {
      setLoading(true);
      const localQuery = query(
        collection(firestore, COLLECTION),
        orderBy("createdAt", "desc"),
        limit(settings.page.rowsPerPage)
      );

      return onSnapshot(localQuery, (doc) => {
        const data = doc.docs.map(genDoc<Log>()).filter((item: Log) => {
          if (
            currentUser &&
            currentUser.roles.includes(settings.app.highestRole)
          ) {
            return true;
          }
          return item;
        });

        setFirstVisible(doc.docs[0]);
        setLastVisible(doc.docs[doc.docs.length - 1]);
        setLogs(data);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    const data = await getDocs(collection(firestore, "Users"));
    setUsers(data.docs.map(genDoc<AdminUser>()));
  };

  useEffect(() => {
    setLoading(true);
    const unsub1 = fetchCount();
    const unsub2 = fetchFirstLogs();
    fetchUsers();
    setLoading(false);

    return () => {
      typeof unsub1 === "function" && unsub1();
      typeof unsub2 === "function" && unsub2();
    };
  }, []);

  return (
    <>
      <SearchBar title="Journaux" hideSearch={true} />

      <LogTable
        loading={loading}
        data={logs}
        count={count}
        noMoreData={logs.length <= count}
        rows={LogsTableRows(users)}
        rowsPerPage={settings.page.rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
      />
    </>
  );
};

export default Logs;
