import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//MUI
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

// Icons
import { MdExitToApp } from "react-icons/md";

// Firebase
import { auth } from "../../firebase/firebase";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Assets
import avatar from "../../assets/images/profile.jpg";

// Components
import UserDialog from "../adminUsers/AdminUserDialog";

const UserMenu: React.FC = () => {
  const { currentUser } = useFetchUser();
  const navigate = useNavigate();

  const [userMenu, setUserMenu] = useState<any>(null);
  const [openProfile, setOpenProfile] = useState<boolean>(false);

  return (
    <div>
      <Button onClick={(e) => setUserMenu(e.currentTarget)}>
        <Avatar src={avatar} />
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={() => setUserMenu(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <MenuItem
          component={Link}
          to="/login"
          onClick={() => {
            auth.signOut();
            setUserMenu(null);
            navigate("/login");
          }}
          role="button"
        >
          <ListItemIcon className="min-w-40">
            <MdExitToApp />
          </ListItemIcon>
          <ListItemText primary="Déconnexion" />
        </MenuItem>
      </Popover>
      {openProfile && (
        <UserDialog
          open={openProfile}
          setOpen={setOpenProfile}
          userId={currentUser && currentUser.id ? currentUser.id : ""}
        />
      )}
    </div>
  );
};

export default UserMenu;
