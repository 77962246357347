import React, { useState } from "react";

// Mui components
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// Icons
import { MdSearch, MdClear } from "react-icons/md";

interface Props {
  title: string;
  searchTitle?: string;
  hideSearch?: boolean;
  // eslint-disable-next-line
  onSearch?: (query: string) => void;
}

const SearchBar: React.FC<Props> = ({
  title,
  searchTitle = "",
  hideSearch = false,
  onSearch = null,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="searchbar__container">
      <Grid container>
        <Grid item xs={6}>
          <Typography className="searchbar__title" variant="h5" gutterBottom>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {!hideSearch && typeof setSearchQuery === "function" && (
            <TextField
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) =>
                e.key === "Enter" &&
                typeof onSearch === "function" &&
                onSearch(searchQuery)
              }
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setSearchQuery("");
                  typeof onSearch === "function" && onSearch("");
                }
              }}
              fullWidth
              label={searchTitle}
              style={{ marginTop: 10 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchQuery("");
                        typeof onSearch === "function" && onSearch("");
                      }}
                    >
                      {searchQuery ? <MdClear /> : <MdSearch />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchBar;
