// React
import React from "react";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

// Firebase
import { auth } from "../../firebase/firebase";

const Unauthorized: React.FC = () => {
  return (
    <div className="unauthorized__container">
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Access Refused
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Please communicate with an Admin to obtain the proper roles to perform
          this action.
        </Typography>
        <div className="unauthorized__buttons">
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  auth.signOut();
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Unauthorized;
