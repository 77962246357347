// React
import React from "react";
// React-Router-Dom
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Routes
import RouteLayout from "./route";
import routes from "./routes";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      {routes.length > 0 && (
        <Routes>
          {routes.map((r: any, i) => (
            <Route
              path={r.path}
              key={i}
              element={
                <RouteLayout
                  title={r.title}
                  Component={r.Component}
                  LayoutComponent={r.LayoutComponent}
                  isPrivate={r.isPrivate}
                  roles={r.roles}
                  rolesAllowed={r.rolesAllowed}
                />
              }
            ></Route>
          ))}
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Router;
