import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Custom components
import Title from "../general/Title";

// Interfaces
import { AdminUser } from "../../interfaces";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Settings
import settings from "../../settings.json";

// Utils
import { emailRegex } from "../../utils/regex";

// Firebase
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getDoc,
  query,
} from "firebase/firestore";
import { firestore } from "../../firebase/firebase";

// yup validation
const requiredMessage = "Ce champ est requis";

const userSchema = yup.object({
  firstName: yup.string().required(requiredMessage),
  lastName: yup.string().required(requiredMessage),
  email: yup
    .string()
    .matches(emailRegex, "Courriel invalide")
    .required(requiredMessage),
  roles: yup
    .array(yup.string().oneOf(settings.app.roles))
    .required(requiredMessage),
});

const AdminUserInfo: React.FC = () => {
  const { currentUser } = useFetchUser();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<AdminUser>();

  const defaultValues = {
    firstName: user ? user.firstName : "",
    lastName: user ? user.lastName : "",
    email: user ? user.email : "",
    roles: user ? user.roles : [""],
  };

  // Forms
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({ resolver: yupResolver(userSchema), defaultValues });

  const onSubmit = async (data: AdminUser) => {
    try {
      const adminUser: AdminUser = { ...data, id: user?.id };

      if (user && user.id) {
        await updateDoc(doc(firestore, "Admins", user.id), { ...adminUser });

        await addDoc(collection(firestore, "Logs"), {
          id: user.id,
          createdAt: new Date(),
          updatedAt: new Date(),
          isDeleted: false,
          adminId: currentUser && currentUser.id ? currentUser.id : "",
          reason: `Updating user ${
            data && (data as any).email ? (data as any).email : (data as any).id
          }`,
        });

        enqueueSnackbar("User updated!", { variant: "success" });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user) {
      setValue("firstName", user.firstName);
      setValue("lastName", user.lastName);
      setValue("email", user.email);
      setValue("roles", user.roles);
    }
  }, [user]);

  useEffect(() => {
    const fetchUser = async () => {
      const result = doc(firestore, "Admins", id as string);

      const querySnapshot = await getDoc(result);
      setUser(querySnapshot.data() as AdminUser);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <div>
      <Title>{user ? `${user?.firstName} ${user?.lastName}` : "User"}</Title>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.firstName ? errors.firstName.message : ""}
              error={!!errors.firstName?.message}
              label="First Name"
              fullWidth
              required
              {...field}
              style={{ marginBottom: 10 }}
            />
          )}
          name="firstName"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.lastName ? errors.lastName.message : ""}
              error={!!errors.lastName?.message}
              label="Last Name"
              fullWidth
              required
              {...field}
              style={{ marginBottom: 10 }}
            />
          )}
          name="lastName"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <TextField
              helperText={errors.email ? errors.email.message : ""}
              error={!!errors.email?.message}
              label="Email"
              fullWidth
              required
              {...field}
              inputProps={{ readOnly: true }}
              style={{ marginBottom: 10 }}
            />
          )}
          name="email"
          control={control}
        />
        <Controller
          name="roles"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="roles-id">Roles</InputLabel>
              <Select
                labelId="roles-id"
                multiple
                {...field}
                inputProps={{
                  readOnly:
                    currentUser &&
                    currentUser.roles.includes(settings.app.highestRole)
                      ? false
                      : true,
                }}
              >
                {settings.app.roles.map((r) => (
                  <MenuItem key={r} value={r}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{ marginLeft: "auto", marginTop: 20 }}
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AdminUserInfo;
