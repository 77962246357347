import React, { ReactNode } from "react";

//MUI
import Typography from "@mui/material/Typography";

interface Props {
  children: ReactNode;
}

const Title: React.FC<Props> = ({ children }) => {
  return (
    <Typography
      component="h2"
      variant="h5"
      gutterBottom
      style={{ paddingBottom: 10, color: "#076AE1" }}
    >
      {children}
    </Typography>
  );
};

export default Title;
