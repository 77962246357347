// Interfaces
import { Loan } from "../../../interfaces";
// Components
import { formatDate } from "../../../utils/date";

const LoanTableRows = () => {
  return [
    {
      header: "Loan Id",
      body: (u: Loan) => u.id,
    },
    {
      header: "Created at",
      body: (u: Loan) => u.createdAt && formatDate(u.createdAt),
    },
    {
      header: "Loan Amount",
      body: (u: Loan) => u.loanAmount,
    },
    {
      header: "Cryptocurrency",
      body: (u: Loan) => u.cryptocurrency,
    },
    {
      header: "Status",
      body: (u: Loan) => u.status,
    },
  ];
};

export default LoanTableRows;
