//React
import { MdAdd } from "react-icons/md";

// Material UI Core
import Button from "@mui/material/Button";

// Interfaces
import { AdminUser } from "../../interfaces";

interface Props {
  open: boolean;
  setOpen: any;
}

const AdminUsersTable = (props: Props) => {
  return [
    {
      header: "First name",
      body: (u: AdminUser) => u.firstName,
    },
    {
      header: "Last name",
      body: (u: AdminUser) => u.lastName,
    },
    {
      header: "email",
      body: (u: AdminUser) => u.email,
    },
    {
      header: (
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.setOpen(true)}
        >
          <MdAdd />
          Add
        </Button>
      ),
      body: (u: AdminUser) => undefined,
    },
  ];
};

export default AdminUsersTable;
