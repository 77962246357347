// React
import React from "react";
// Component
import TableLoader from "./TableLoader";
import settings from "../../settings.json";
// Mui
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";

interface Props {
  data?: any[];
  loading?: boolean;
  rows?: any[];
  count?: number;
  noMoreData?: boolean;
  rowsPerPage?: number;
  page?: number;
  onDoubleClick?: any;
  onChangePage?: any;
}

const SimpleTable: React.FC<Props> = ({
  data = [],
  loading = false,
  rows = [],
  count = data.length,
  noMoreData = false,
  rowsPerPage = settings.page.rowsPerPage,
  page = 0,
  onDoubleClick = null,
  onChangePage = null,
}) => {
  return (
    <>
      {onChangePage && (
        <TablePagination
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count !== -1 ? count : `plus de ${to}`}`
          }
          labelRowsPerPage={false}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            {!!rows.length &&
              rows.map((r) => (
                <TableCell key={r.header} align="center">
                  {r.header}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || !count ? (
            <TableLoader
              data={data}
              loading={loading}
              noMoreData={noMoreData}
            />
          ) : (
            data.map((row) => (
              <>
                <TableRow
                  onDoubleClick={() => {
                    if (typeof onDoubleClick === "function") onDoubleClick(row);
                  }}
                  style={
                    typeof onDoubleClick === "function"
                      ? { cursor: "pointer" }
                      : {}
                  }
                  hover
                  className="onHover"
                  key={row.id}
                >
                  {!!rows.length &&
                    rows.map((r) => (
                      <TableCell align="center" key={r.header}>
                        {r.body(row)}
                      </TableCell>
                    ))}
                </TableRow>
              </>
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default SimpleTable;
