import { useState } from "react";

// Mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

// Icons
import { MdMail } from "react-icons/md";

// Firebase
import { auth } from "../../firebase/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useSnackbar } from "notistack";

interface Props {
    open: boolean;
    onClose: any;
}

export const ForgotPassword = (props: Props) => {
    const [email, setEmail] = useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (e: any) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                props.onClose();
                enqueueSnackbar("Courriel envoyé", { variant: "success" });
            })
            .catch(err => {
                console.error("Erreur avec l'envoie du message: ", err);
                if (err.code === "auth/invalid-email") enqueueSnackbar("Courriel invalide", { variant: "error" });
                else if (err.code === "auth/user-not-found") enqueueSnackbar("Aucun utilisateur avec ce courriel", { variant: "error" });
            });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="dialog-title">Mot de passe oublié?</DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <DialogContentText>
                        Si vous avez oublié votre mot de passe, veuillez entrer votre adresse courriel. Si ce courriel est valide, vous recevrez un
                        message avec les démarches à suivre afin de réinitialiser votre mot de passe.
                    </DialogContentText>
                    <TextField fullWidth variant="outlined" label="Adresse courriel" value={email} onChange={e => setEmail(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" type="submit">
                        <MdMail className="addIcon" />
                        Envoyer
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
