// Interfaces
import { AdminUser } from "../../interfaces";
import { Log } from "../../interfaces";

// Utils
import { formatDateIntoElapsedTimeFrench } from "../../utils/date";

const LogTableRows = (users: AdminUser[]) => {
    return [
        {
            header: "Date",
            body: (l: Log) => l.createdAt && formatDateIntoElapsedTimeFrench(l.createdAt),
        },
        {
            header: "Utilisateur",
            body: (l: Log) => users.find(u => u.id === l.adminId)?.email,
        },
        {
            header: "Raison",
            body: (l: Log) => l.reason,
        },
    ];
};

export default LogTableRows;
