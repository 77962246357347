// React
import React from "react";
//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";

interface Props {
  invoices: any;
}

const Payments: React.FC<Props> = ({ invoices }) => {
  const isoStringToDate = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice Id</TableCell>
            <TableCell align="center">Due date</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices?.length > 0 &&
            invoices.map((invoice: any) => (
              <TableRow component="th" scope="row" key={invoice.Id}>
                <TableCell>
                  <Link
                    href={`https://sandbox.zumrails.com/#/invoices/${invoice.Id}`}
                    target="_blank"
                  >
                    {invoice.Id}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {isoStringToDate(invoice.DueDate)}
                </TableCell>
                <TableCell align="center">{invoice.Amount}</TableCell>

                <TableCell align="center">
                  <div>
                    {invoice.InvoiceStatus === "Paid" ? (
                      <Chip label="Paid" color="success" />
                    ) : (
                      <Chip label="Late" color="error" />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Payments;
