// React
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// React-Router-Dom
import { useNavigate } from "react-router-dom";
// Interfaces
import { User, Loan } from "../../interfaces";
// React-Router-Dom
import { useLocation } from "react-router-dom";
// Icons
import { MdArrowRightAlt } from "react-icons/md";
// Firebase
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore, functions } from "../../firebase/firebase";
import { httpsCallable } from "firebase/functions";
// Custom components
import Title from "../general/Title";
import GeneralInfo from "./tabs/General";
import Payments from "./tabs/Payments";
import Withdrawal from "./tabs/Withdrawal";
import Contract from "./tabs/Contract";
//MUI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const LoanInfo: React.FC = () => {
  // Hooks
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // States
  const [user, setUser] = useState<User>();
  const [loan, setLoan] = useState<Loan>(state as Loan);
  const [loanStatus, setLoanStatus] = useState((state as Loan).status);
  const [value, setValue] = useState(0);
  const [invoices, setInvoices] = useState<any>();
  const [open, setOpen] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const transactionProperFormat = /^[0-9a-f]{64}$/i.test(transactionHash);
    if (!transactionProperFormat && loanStatus === "withdrawn") {
      enqueueSnackbar("Please add a transaction Hash before confirming", {
        variant: "error",
      });
      return;
    }

    try {
      setOpen(false);
      const loanRef = doc(firestore, "Loans", loan.id);
      await updateDoc(loanRef, { status: loanStatus, transactionHash });
      setLoan({ ...loan, status: loanStatus, transactionHash });
      setTransactionHash("");
    } catch (e) {
      console.log(e);
    }
  };

  // FETCH user
  useEffect(() => {
    const fetchUser = async () => {
      const result = doc(firestore, "Users", (state as Loan).userId);
      const querySnapshot = await getDoc(result);
      setUser(querySnapshot.data() as User);
    };
    fetchUser();
  }, []);

  // FETCH invoices
  useEffect(() => {
    const fetchPayments = async () => {
      const fetchPayments = httpsCallable(functions, "zum-fetchAllInvoices");
      const response: any = (
        await fetchPayments(
          JSON.stringify({
            firstInvoiceId: loan.zum?.firstInvoiceId,
            subscriptionId: loan.zum.subscriptionId,
            lastInvoiceId: loan.zum?.lastInvoiceId,
            missedPaymentIds: loan.zum?.missedPaymentsInvoices,
          })
        )
      ).data as any;
      let invoices = [
        response.firstInvoice.result,
        ...response.subInvoices.result,
      ];
      if (response.missedPaymentsInvoices)
        invoices.push(
          ...response.missedPaymentsInvoices.map((invoice: any) => {
            return invoice.result;
          })
        );

      if (response.lastInvoice) invoices.push(response.lastInvoice.result);

      //prettier-ignore
      invoices.sort((a, b) =>  new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getTime());
      setInvoices(invoices);
    };
    if (loan) fetchPayments();
  }, []);

  return (
    <div className="loan__container">
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Typography>
            Are you sure you want to change the status from
            <span style={{ fontWeight: "bold" }}>
              {"  "}
              {loan.status.toUpperCase()}
              {"  "}
            </span>
            to{" "}
            <span style={{ fontWeight: "bold" }}>
              {loanStatus.toUpperCase()}?
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          {loanStatus === "withdrawn" && (
            <TextField
              label="Transaction Hash"
              fullWidth
              variant="standard"
              onChange={(e) => setTransactionHash(e.target.value)}
            />
          )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              style={{ marginRight: "20px" }}
              variant="outlined"
              type="submit"
              color="primary"
            >
              Cancel
            </Button>
            <form onSubmit={handleSubmit}>
              <Button variant="contained" type="submit" color="primary">
                Confirm
              </Button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      <div className="header">
        <Title>
          <div
            className="user__button"
            onClick={() => navigate(`/user/${user?.id}`)}
            style={{ display: "flex", alignItems: "center" }}
          >
            {user ? `${user?.firstName} ${user?.lastName}` : "User"}

            <Icon>
              <MdArrowRightAlt></MdArrowRightAlt>
            </Icon>
          </div>
        </Title>
        <div>
          <Select
            variant="standard"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={loan.status}
            label="Status"
            onChange={(e) => {
              setLoanStatus(e.target.value);
              setOpen(true);
            }}
          >
            <MenuItem value={"ongoing"}>Ongoing</MenuItem>
            <MenuItem value={"paid"}>Paid</MenuItem>
            <MenuItem value={"withdrawn"}>Withdrawn</MenuItem>
            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
          </Select>
        </div>
      </div>
      <div>
        <div
          style={{
            borderBottom: 15,
            borderColor: "divider",
            marginBottom: 25,
          }}
        >
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={(e, index) => {
              setValue(index);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="General" value={0} iconPosition="start" />
            <Tab label="Payments" value={1} />
            <Tab label="Withdrawal" value={2} />
            <Tab label="Contract" value={3} iconPosition="end" />
          </Tabs>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "85%" }}>
            {value === 0 && user && <GeneralInfo user={user} loanProp={loan} />}
            {value === 1 && user && <Payments invoices={invoices} />}
            {value === 2 && user && <Withdrawal loan={loan} />}
            {value === 3 && user && <Contract loan={loan} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanInfo;
