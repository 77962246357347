import { useEffect, useState } from "react";
// Interfaces
import { Loan } from "../interfaces";
// Hooks
import { singletonHook } from "react-singleton-hook";
// Firebase
import { firestore } from "../firebase/firebase";
import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
} from "firebase/firestore";

const initState = {
  withdrawableLoans: [],
  loading: false,
};

const useFetchWithdrawableLoansImpl = () => {
  const [withdrawableLoans, setWithdrawableLoans] = useState<Loan[]>([]);

  useEffect(() => {
    const fetchWithdrawableLoans = async () => {
      const withdrawableLoansQuery = query(
        collection(firestore, "Loans"),
        orderBy("createdAt", "asc"),
        where("status", "==", "paid")
      );
      onSnapshot(withdrawableLoansQuery, (querySnapshot) => {
        if (querySnapshot.size === 0) setWithdrawableLoans([]);
        querySnapshot.forEach((doc: any) => {
          const docData = doc.data();
          docData.createdAt = new Date(docData.createdAt.seconds * 1000);
          setWithdrawableLoans([...withdrawableLoans, docData]);
        });
      });
    };
    fetchWithdrawableLoans();
  }, []);

  return { withdrawableLoans };
};

export const useFetchWithdrawableLoans = singletonHook(
  initState,
  useFetchWithdrawableLoansImpl
);
