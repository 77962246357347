// Interfaces
import { AdminUser } from "../../interfaces";

const UserTableRows = () => {
  return [
    {
      header: "First name",
      body: (u: AdminUser) => u.firstName,
    },
    {
      header: "Last name",
      body: (u: AdminUser) => u.lastName,
    },
    {
      header: "email",
      body: (u: AdminUser) => u.email,
    },
  ];
};

export default UserTableRows;
