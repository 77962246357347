// Routes
import { CustomRouteProps } from "./route";
// Settings
import settings from "../settings.json";
// Components
import Login from "../components/pages/Login";
import Home from "../components/pages/Home";
import MainLayout from "../components/layout/main/MainLayout";
import BaseLayout from "../components/layout/base/BaseLayout";
import AdminUsers from "../components/adminUsers/AdminUsers";
import AdminUserInfo from "../components/adminUsers/AdminUser";
import Users from "../components/users/Users";
import UserInfo from "../components/users/User";
import Loan from "../components/loans/Loan";
import Loans from "../components/loans/Loans";
import Logs from "../components/logs/Logs";
import WithdrawableLoans from "../components/loans/WithdrawableLoans";

const Routes: Array<CustomRouteProps> = [
  {
    Component: Login,
    path: "/login",
    exact: true,
    title: "Login",
    LayoutComponent: BaseLayout,
    rolesAllowed: [],
    isPrivate: false,
  },
  {
    Component: Home,
    path: "/",
    exact: true,
    title: "Home",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
  {
    Component: Home,
    path: "/home",
    exact: true,
    title: "Home",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
  {
    Component: AdminUsers,
    path: "/admins",
    exact: true,
    title: "Admin Users",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
  {
    Component: AdminUserInfo,
    path: "/admin/:id",
    exact: false,
    title: "Admin",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
  {
    Component: Users,
    path: "/users",
    exact: false,
    title: "Users",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
  {
    Component: UserInfo,
    path: "/user/:id",
    exact: false,
    title: "User",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
  {
    Component: Logs,
    path: "/journals",
    exact: true,
    title: "Journals",
    LayoutComponent: MainLayout,
    rolesAllowed: [settings.app.highestRole],
    isPrivate: true,
  },
  {
    Component: Loans,
    path: "/loans",
    exact: false,
    title: "Loans",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
  {
    Component: Loan,
    path: "/loan/:id",
    exact: false,
    title: "Loans",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },

  {
    Component: WithdrawableLoans,
    path: "/withdrawable-loans",
    exact: false,
    title: "Withdrawable Loans",
    LayoutComponent: MainLayout,
    rolesAllowed: settings.app.roles,
    isPrivate: true,
  },
];

export default Routes;
