import moment from "moment";
import "moment/locale/fr-ca";

// Locale
moment.locale("en-ca");

export const formatDateTimeStripe = (datetime: Date) => {
  return moment(datetime).format("DD/MM/YY HH:mm");
};

export const formatDateTime = (datetime: Date) => {
  return moment(datetime).format("DD MMMM HH:mm:ss");
};

export const formatDate = (datetime: Date) =>
  moment(datetime).format("DD/MM/YYYY");

export const formatDateIntoElapsedTimeFrench = (date: Date) => {
  if (typeof date == "number") {
    const timestamp: any = date;
    const timestampInMilliseconds = timestamp;
    const newDate = new Date(timestampInMilliseconds);
    date = newDate;
  }
  const MINUTE_IN_SECONDS = 60;
  const HOUR_IN_SECONDS = 360;
  const DAY_IN_SECONDS = 86400;

  const now = Date.now();
  const diff = now - date.getTime();
  const diffSeconds = diff / 1000;

  if (diffSeconds < MINUTE_IN_SECONDS) {
    return `Il y a ${Math.ceil(diffSeconds)} seconde${
      Math.ceil(diffSeconds) > 1 ? "s" : ""
    }`;
  } else if (diffSeconds < HOUR_IN_SECONDS) {
    return `Il y a ${Math.ceil(diffSeconds / 60)} minute${
      Math.ceil(diffSeconds / 60) > 1 ? "s" : ""
    }`;
  } else if (diffSeconds < DAY_IN_SECONDS) {
    return `Il y a ${Math.ceil(diffSeconds / 3600)} heure${
      Math.ceil(diffSeconds / 3600) > 1 ? "s" : ""
    }`;
  } else {
    return formatDateTime(date);
  }
};

export const formatDateIntoElapsedTimeEnglish = (date: Date) => {
  if (typeof date == "number") {
    const timestamp: any = date;
    const timestampInMilliseconds = timestamp;
    const newDate = new Date(timestampInMilliseconds);
    date = newDate;
  }
  const MINUTE_IN_SECONDS = 60;
  const HOUR_IN_SECONDS = 360;
  const DAY_IN_SECONDS = 86400;

  const now = Date.now();
  const diff = now - date.getTime();
  const diffSeconds = diff / 1000;

  if (diffSeconds < MINUTE_IN_SECONDS) {
    return `${Math.ceil(diffSeconds)} second${
      Math.ceil(diffSeconds) > 1 ? "s" : ""
    } ago`;
  } else if (diffSeconds < HOUR_IN_SECONDS) {
    return `${Math.ceil(diffSeconds / 60)} minute${
      Math.ceil(diffSeconds / 60) > 1 ? "s" : ""
    } ago`;
  } else if (diffSeconds < DAY_IN_SECONDS) {
    return `${Math.ceil(diffSeconds / 3600)} hour${
      Math.ceil(diffSeconds / 3600) > 1 ? "s" : ""
    } ago`;
  } else {
    return formatDateTime(date);
  }
};
