import React from "react";

// Components
import Title from "../general/Title";

const Home: React.FC = () => {
  return (
    <>
      <Title>Home</Title>
      <div style={{ textAlign: "justify" }}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu
          quam hendrerit, gravida ipsum a, varius nulla. Proin suscipit eros ac
          mauris ultrices porttitor. Integer erat ipsum, aliquam eu nibh non,
          porttitor auctor lacus. Suspendisse posuere dolor mi, sit amet porta
          lorem convallis vitae. Morbi euismod libero arcu, nec ornare lacus
          pharetra vitae. Nullam pellentesque tortor augue, id mollis ex commodo
          placerat. Quisque sed tempor enim. Morbi ex ligula, mattis sed sapien
          quis, dictum rutrum dolor. Cras a interdum enim, id fringilla felis.
          Praesent turpis nisl, sodales eget lacus non, commodo rhoncus odio.
          Fusce molestie nisi ut leo placerat sagittis. Morbi eu neque quam.
          Praesent ipsum nisl, blandit nec metus vel, pretium feugiat orci.
          Nulla suscipit elit sit amet elit finibus tincidunt. Aenean vehicula,
          magna eu venenatis blandit, metus elit vestibulum tellus, non mollis
          ante nulla nec urna. Nullam vel convallis sapien.
        </p>
        <p>
          Vivamus aliquam, nulla ut luctus rhoncus, erat magna placerat est, vel
          porta magna tortor in sem. Suspendisse potenti. Nulla convallis
          euismod risus non scelerisque. Donec augue arcu, convallis vitae
          mattis sit amet, eleifend a sem. Donec quis varius odio. Morbi sit
          amet pulvinar lorem, in vehicula tortor. Quisque tellus turpis,
          volutpat sed auctor in, mollis a arcu. Sed sed ex nec sapien
          sollicitudin fringilla. In quis imperdiet mi. Etiam non eros vel augue
          venenatis volutpat. Curabitur massa justo, sodales ut tortor ac,
          dictum varius ipsum.
        </p>
        <p>
          Mauris sem neque, volutpat ac pharetra sed, venenatis at quam. Sed
          fermentum sapien sed auctor dapibus. Nullam cursus et est quis
          accumsan. Suspendisse vestibulum felis bibendum risus commodo, sed
          egestas ipsum faucibus. Cras et volutpat ligula. Maecenas ornare
          eleifend ipsum sed condimentum. Suspendisse nec ultricies quam. Fusce
          lobortis varius urna sit amet imperdiet. In sed hendrerit libero.
          Suspendisse ullamcorper tincidunt dolor, vitae pharetra ex commodo id.
          Aliquam vel metus id nibh lobortis egestas. Sed tincidunt mi et
          vestibulum euismod. Vivamus convallis eleifend lectus, eget viverra
          sapien ultrices sed.
        </p>
        <p>
          Maecenas in odio mi. Mauris sed nisi quis dui scelerisque tempus sed
          sit amet odio. Nunc vitae ex nec risus vestibulum ornare. Duis sapien
          lacus, tincidunt a consequat sit amet, hendrerit ut odio. Nulla
          sollicitudin, ex sit amet ornare maximus, nulla est sagittis ipsum,
          vel cursus quam eros id tellus. Aliquam a augue lobortis, euismod nunc
          vitae, efficitur turpis. Morbi aliquam massa pellentesque, ultrices
          eros nec, ornare nisi. Morbi et dapibus magna. Quisque vehicula
          pellentesque vulputate. Aenean quis varius felis. Nulla vitae leo a
          tortor interdum congue. Curabitur id pulvinar tortor. Sed luctus
          tincidunt est ut malesuada. Aliquam quam metus, pharetra quis laoreet
          gravida, eleifend et lectus. Interdum et malesuada fames ac ante ipsum
          primis in faucibus.
        </p>
        <p>
          Sed erat dui, tincidunt in rutrum id, dapibus et ipsum. Morbi molestie
          pretium leo quis ultrices. Integer auctor consequat purus, venenatis
          pulvinar purus cursus sit amet. Duis mollis interdum felis, in
          venenatis leo fringilla vitae. Nullam vehicula nisi vel sapien
          vestibulum condimentum. Integer auctor ante sed lorem condimentum, nec
          varius mauris convallis. Vivamus nulla ipsum, aliquam nec maximus ac,
          condimentum sed risus. Ut consequat at orci a faucibus. Duis iaculis
          arcu hendrerit, consequat orci et, consectetur dui. Maecenas sit amet
          justo lorem. Nunc quis quam tempor, dictum tortor nec, elementum
          libero.
        </p>
      </div>
    </>
  );
};

export default Home;
