// Custom Hooks
import { useFetchUser } from "../hooks/useFetchUser";
// React-Router-Dom
import { useLocation, Navigate } from "react-router-dom";
// Settings
import settings from "../settings.json";
import Unauthorized from "../components/pages/Unauthorized";

export interface CustomRouteProps {
  Component: React.FC;
  LayoutComponent: React.FC<any> | null;
  readonly isPrivate?: boolean;
  readonly roles?: string[];
  readonly rolesAllowed?: string[];
  readonly title?: string;
  [index: string]: any; // rest
}

const RouteLayout = ({
  Component,
  isPrivate = true,
  LayoutComponent = null,
  roles = [],
  rolesAllowed = [],
  title,
}: CustomRouteProps) => {
  const location = useLocation();
  const { currentUser } = useFetchUser();

  if (title) document.title = `${title} - ${settings.app.title}`;

  // If user is NOT LOGGED IN, always redirect them to Login page
  if (!currentUser && location.pathname !== "/login")
    return <Navigate to="/login" />;

  if (currentUser) roles = currentUser.roles;

  // DENY access to user not having the appropriate roles
  if (
    (currentUser && currentUser.roles && currentUser.roles.length === 0) ||
    (rolesAllowed.length > 0 &&
      !!roles.length &&
      !rolesAllowed.some((r) => roles.includes(r)))
  ) {
    return <Unauthorized />;
  }

  if (LayoutComponent === null) return <Component />;

  return (
    <LayoutComponent>
      <Component />
    </LayoutComponent>
  );
};

export default RouteLayout;
