import { createRef } from "react";
import { SnackbarProvider } from "notistack";

// Mui
import { ThemeProvider } from "@mui/material/styles";

// Router
import Router from "./routes/router";

// Settings
import versionObj from "./version.json";

// styles
import "./scss/App.scss";

// Theme
import theme from "./theme";

function App() {
    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    const snackbarRef: any = createRef();

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                style={{ margin: 2 }}
                ref={snackbarRef}
                // hideIconVariant
                // TransitionComponent={TransitionLeft}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={4000}
                // action={key => (
                //     <Button
                //         style={{ color: "white" }}
                //         onClick={() => {
                //             console.log("CLOSE");

                //             snackbarRef.current.closeSnackbar(key);
                //         }}
                //     >
                //         FERMER
                //     </Button>
                // )}
            >
                <Router />
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
