// React
import React, { useState } from "react";
// Interfaces
import { Loan } from "../../../interfaces";
// Utils
import { formatDateTime } from "../../../utils/date";
//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

interface Props {
  loan: Loan;
}

const Contract: React.FC<Props> = ({ loan }) => {
  //States
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <TableContainer component={Paper}>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <img
            onClick={() => setOpen(true)}
            alt=""
            src={loan.signature.signatureImage}
            style={{ maxWidth: "100%" }}
          />
        </DialogTitle>
      </Dialog>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Signature</TableCell>
            <TableCell align="center">TimeStamp</TableCell>
            <TableCell align="center">Ip</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" style={{ maxWidth: "150px" }}>
              <img
                onClick={() => setOpen(true)}
                alt=""
                src={loan.signature.signatureImage}
                style={{ maxWidth: "150px", boxSizing: "border-box" }}
              />
            </TableCell>
            <TableCell align="center">
              {formatDateTime(new Date(loan.signature.timestamp.seconds))}
            </TableCell>
            <TableCell align="center"> {loan.signature.ip}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Contract;
