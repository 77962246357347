import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Interfaces
import { AdminUser } from "../../interfaces";

// Custom components
import UserTable from "../general/SimpleTable";
import UserDialog from "./AdminUserDialog";
import AdminUsersTable from "./AdminUsersTable";
import SearchBar from "../general/SearchBar";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Settings
import settings from "../../settings.json";

// Firebase
import { firestore } from "../../firebase/firebase";
import {
  collection,
  query,
  orderBy,
  doc,
  onSnapshot,
  startAfter,
  limit,
  endBefore,
  QueryDocumentSnapshot,
  DocumentData,
  getDocs,
  where,
} from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

const COLLECTION = "Admins";

const AdminUsers: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser, loading, setLoading } = useFetchUser();

  // States
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [firstVisible, setFirstVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleNextPage = async () => {
    try {
      if (users.length !== 0) {
        setLoading(true);
        const localQuery = query(
          collection(firestore, COLLECTION),
          orderBy("email", "asc"),
          limit(settings.page.rowsPerPage),
          startAfter(lastVisible)
        );

        return onSnapshot(localQuery, (doc) => {
          const data = doc.docs
            .map(genDoc<AdminUser>())
            .filter((item: AdminUser) => {
              if (
                currentUser &&
                currentUser.roles.includes(settings.app.highestRole)
              ) {
                return true;
              }
              return item;
            });

          setFirstVisible(doc.docs[0]);
          setLastVisible(doc.docs[doc.docs.length - 1]);
          setUsers(data);
          setLoading(false);
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = async () => {
    try {
      setLoading(true);
      const localQuery = query(
        collection(firestore, COLLECTION),
        orderBy("email", "asc"),
        limit(settings.page.rowsPerPage),
        endBefore(firstVisible)
      );

      return onSnapshot(localQuery, (doc) => {
        const data = doc.docs
          .map(genDoc<AdminUser>())
          .filter((item: AdminUser) => {
            if (
              currentUser &&
              currentUser.roles.includes(settings.app.highestRole)
            ) {
              return true;
            }
            return item;
          });

        setFirstVisible(doc.docs[0]);
        setLastVisible(doc.docs[doc.docs.length - 1]);
        setUsers(data);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCount = () => {
    try {
      setLoading(true);
      return onSnapshot(doc(firestore, "Metadata/Admins"), (document) => {
        if (!document.exists) setCount(0);

        const data = document.data() as any;

        setCount(data.count);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e: any, newPage: number) => {
    if (page < newPage) handleNextPage();
    else if (page > newPage) handlePreviousPage();

    setPage(newPage);
  };

  const fetchFirstUsers = () => {
    try {
      setLoading(true);
      const localQuery = query(
        collection(firestore, COLLECTION),
        orderBy("email", "asc"),
        limit(settings.page.rowsPerPage)
      );

      return onSnapshot(localQuery, (doc) => {
        const data = doc.docs
          .map(genDoc<AdminUser>())
          .filter((item: AdminUser) => {
            if (
              currentUser &&
              currentUser.roles.includes(settings.app.highestRole)
            ) {
              return true;
            }
            return item;
          });

        setFirstVisible(doc.docs[0]);
        setLastVisible(doc.docs[doc.docs.length - 1]);
        setUsers(data);
        setLoading(false);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchFilteredUsers = async (email: string) => {
    const localQuery = query(
      collection(firestore, COLLECTION),
      where("email", "==", email)
    );

    const data = await getDocs(localQuery);

    const localFilteredUsers = data.docs
      .map(genDoc<AdminUser>())
      .filter((item: AdminUser) => {
        if (
          currentUser &&
          currentUser.roles.includes(settings.app.highestRole)
        ) {
          return true;
        }
        return item;
      })
      .filter(
        (u) =>
          u.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          u.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          u.lastName.toLowerCase().includes(searchQuery.toLowerCase())
      );

    if (localFilteredUsers.length > 0) {
      const firstIndex = data.docs.findIndex((d) => {
        const localData = d.data();
        return localData.id === localFilteredUsers[0].id;
      });

      const lastIndex = data.docs.findIndex((d) => {
        const localData = d.data();

        if (localFilteredUsers.length >= 10) {
          return (
            localData.id ===
            localFilteredUsers[settings.page.rowsPerPage - 1].id
          );
        } else {
          return (
            localData.id ===
            localFilteredUsers[localFilteredUsers.length - 1].id
          );
        }
      });

      setFirstVisible(data.docs[firstIndex]);
      setLastVisible(data.docs[lastIndex]);
    }

    setCount(settings.page.rowsPerPage);
    // setCount(localFilteredUsers.length);
    setUsers(localFilteredUsers);
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    setPage(0);
  };

  useEffect(() => {
    let unsub1: any = null;
    let unsub2: any = null;

    if (searchQuery) fetchFilteredUsers(searchQuery);
    else {
      setLoading(true);
      unsub1 = fetchCount();
      unsub2 = fetchFirstUsers();
      setLoading(false);
    }

    return () => {
      typeof unsub1 === "function" && unsub1();
      typeof unsub2 === "function" && unsub2();
    };
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    const unsub1 = fetchCount();
    const unsub2 = fetchFirstUsers();
    setLoading(false);

    return () => {
      typeof unsub1 === "function" && unsub1();
      typeof unsub2 === "function" && unsub2();
    };
  }, []);

  return (
    <>
      <SearchBar
        title="Admins"
        searchTitle="Search by email"
        onSearch={handleSearch}
      />
      <UserTable
        loading={loading}
        data={users}
        count={count}
        rows={AdminUsersTable({ open, setOpen })}
        noMoreData={users.length <= count}
        rowsPerPage={settings.page.rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onDoubleClick={(u: AdminUser) => navigate(`/admin/${u.id}`)}
      />
      {open && <UserDialog open={open} setOpen={setOpen} userId={""} />}
    </>
  );
};

export default AdminUsers;
