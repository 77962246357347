// React
import React, { useState } from "react";
// React-Router-Dom
import { Navigate } from "react-router-dom";
// Hooks
import { useSnackbar } from "notistack";
// Custom Hooks
import { useFetchUser } from "../../hooks/useFetchUser";
// Components
import { ForgotPassword } from "./forgotPassword";
// Firebase
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";
// Mui + UI
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { MdLockOutline } from "react-icons/md";
import logo from "../../assets/images/logos/brand_placeholder.png";

const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, fetchingUser } = useFetchUser();

  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e: any) {
      console.error("onSubmit", e);
      if (e.code === "auth/user-not-found")
        enqueueSnackbar("Aucun compte associé avec ce courriel", {
          variant: "error",
        });
      else if (e.code === "auth/wrong-password")
        enqueueSnackbar("Combinaison de courriel et mot de passe incorrecte", {
          variant: "error",
        });
      else if (e.code === "auth/invalid-email")
        enqueueSnackbar("Courriel invalide", { variant: "error" });
    }
  };

  if (fetchingUser) {
    return (
      <Backdrop open={fetchingUser} className="login__backdrop">
        <CircularProgress className="login__spinner" />
        <img src={logo} alt="" />
      </Backdrop>
    );
  }

  if (currentUser) {
    return <Navigate to="/home" replace />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className="login__container">
        <Avatar className="login__avatar">
          <MdLockOutline />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connection
        </Typography>
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            id="email"
            margin="normal"
            label="Courriel"
            variant="outlined"
            type="text"
            onChange={(e) => setEmail(e.currentTarget.value)}
            required
            fullWidth
          />
          <TextField
            id="password"
            margin="normal"
            label="Mot de passe"
            variant="outlined"
            type="password"
            onChange={(e) => setPassword(e.currentTarget.value)}
            required
            fullWidth
          />

          <Button type="submit" fullWidth variant="contained">
            Connection
          </Button>
        </form>
        <Grid container>
          <Grid item xs></Grid>
          <Grid item>
            <Button onClick={() => setOpen(true)}>Forgot Password?</Button>
          </Grid>
        </Grid>
      </div>
      <ForgotPassword open={open} onClose={() => setOpen(false)} />
    </Container>
  );
};

export default Login;
